import React from "react";
// import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// import { paths } from "../../routes/paths";

import { retrunLanguage } from "../../utils/languageSelect";
import { ExclaimIcon } from "../../assets";

const ExistingReservation = (props) => {
  const { t, i18n } = useTranslation("dialog");

  const { open, setOpen } = props;

  // const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        margin: "0",
        textAlign: "center",
      }}
      className="cart-dialog"
      PaperProps={{
        sx: {
          overflow: "visible",
          margin: retrunLanguage(i18n)
            ? "0px 27px 0px 0px"
            : "0px 0px 0px 27px",
          width: "327px",
          right: retrunLanguage(i18n) ? "-5px" : "unset",

          "@media (max-width: 500px)": {
            width: "375px",
            margin: "0px 0px 0px 0px",
          },

          "@media (max-width: 400px)": {
            width: "90%",
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="dialog-title title-cart">
        <img src={ExclaimIcon}></img>

        <div
          style={{
            position: "absolute",
            top: "8px",
            right: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon
            sx={{
              width: "24px",
              fontSize: "24px",
              cursor: "pointer",
              color: "#707070 !important",
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent
        className="dialog-content-cart"
        sx={{ padding: "0px 12px !important" }}
      >
        <span style={{ marginBottom: "14px", display: "block" }}>
          {t("existingReservation")}
        </span>

        <span>{t("newReservation")}</span>
      </DialogContent>

      <div className="btn-navigate dinein-btn-navigate">
        <Button className="common-st add-btn" onClick={() => setOpen(false)}>
          {t("goBack")}
        </Button>
      </div>
    </Dialog>
  );
};

export default ExistingReservation;
