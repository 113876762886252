import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import DetailsButton from "../../socialButtonTabs/detailsButtons";
import { Footer } from "../../footer/footerContent";

import { paths } from "../../../routes/paths";

import logout from "../../../api/customer/logout";
import clearCart from "../../../api/cart/clearCart";

import { clearAddressList } from "../../../store/reducers/addressSlice";
import { resetCart } from "../../../store/reducers/cartSlice";
import { removeTypeStatus } from "../../../store/reducers/resturantsSlice";

import { retrunLanguage } from "../../../utils/languageSelect";
import {
  BoxIcon,
  EditIcon,
  ExclaimIcon,
  LogoutIcon,
  MapIcon,
  Cutlery,
} from "../../../assets";

import "./style.css";

const ProfileStructure = ({
  name,
  phoneNumber,
  email,
  imageUrl,
  onEditClick,
}) => {
  const { t, i18n } = useTranslation("translation");

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const handleLogoutClick = () => {
    setOpenLogoutDialog(true);
  };

  const handleLogoutConfirm = () => {
    const token = localStorage.getItem("token");

    clearCart(token)
      .then((res) => {
        logout(token)
          .then(() => {
            localStorage.clear();
            sessionStorage.removeItem("cartEmpty");
            dispatch(resetCart());
            dispatch(clearAddressList());
            dispatch(removeTypeStatus());
            navigate("/");
          })
          .catch((e) => {
            console.error(e);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    setOpenLogoutDialog(false);
  };

  const handleLogoutCancel = () => {
    setOpenLogoutDialog(false);
  };

  return (
    <div className="profile-section">
      <div style={{ height: "100%" }}>
        <div className="left-section">
          <img
            src={imageUrl}
            alt="Profile"
            className={`image-style ${
              retrunLanguage(i18n) ? "img-right" : "img-left"
            }`}
          />

          <div
            className={`profile-name ${
              retrunLanguage(i18n) ? "profile-right" : "profile-left"
            }`}
          >
            {name}
          </div>

          <div
            className={`profile-number ${
              retrunLanguage(i18n) ? "numb-right" : "numb-left"
            }`}
          >
            {phoneNumber}
          </div>

          <div
            className={`profile-email ${
              retrunLanguage(i18n) ? "email-right" : "email-left"
            }`}
          >
            {email}
          </div>
        </div>

        <div className="right-section">
          <button
            onClick={onEditClick}
            className={`profile-edit-button ${
              retrunLanguage(i18n) ? "btn-left" : "btn-right"
            }`}
          >
            <img src={EditIcon} alt="Edit" />
          </button>
        </div>
        <div
          className="inline-container"
          style={{
            left: retrunLanguage(i18n) ? "unset" : "13px",
            right: retrunLanguage(i18n) ? "13px" : "unset",
          }}
          onClick={() => {
            navigate(paths.addresses, { state: { path: location.pathname } });
          }}
        >
          <img className="map-style" src={MapIcon} alt="My Addresses" />
          <p
            style={{
              left: retrunLanguage(i18n) ? "unset" : "10px",
              right: retrunLanguage(i18n) ? "13px" : "unset",
            }}
          >
            {t("profile.myAddresses")}
          </p>
        </div>
        <div
          className="inline-container2"
          style={{
            left: retrunLanguage(i18n) ? "unset" : "13px",
            right: retrunLanguage(i18n) ? "13px" : "unset",
          }}
          onClick={() => {
            navigate(paths.myorder);
          }}
        >
          <img className="box-style" src={BoxIcon} alt="My Orders" />
          <p
            style={{
              left: retrunLanguage(i18n) ? "unset" : "10px",
              right: retrunLanguage(i18n) ? "13px" : "unset",
            }}
          >
            {t("profile.myOrders")}
          </p>
        </div>
        <div
          className="inline-container3"
          style={{
            left: retrunLanguage(i18n) ? "unset" : "13px",
            right: retrunLanguage(i18n) ? "13px" : "unset",
          }}
          onClick={() => {
            navigate(paths.myReservations);
          }}
        >
          <img className="box-style" src={Cutlery} alt="My Reservations" />
          <p
            style={{
              left: retrunLanguage(i18n) ? "unset" : "10px",
              right: retrunLanguage(i18n) ? "13px" : "unset",
            }}
          >
            {t("profile.myReservations")}
          </p>
        </div>
        <div
          className="inline-container4"
          onClick={handleLogoutClick}
          style={{
            left: retrunLanguage(i18n) ? "unset" : "13px",
            right: retrunLanguage(i18n) ? "13px" : "unset",
          }}
        >
          <img className="box-style" src={LogoutIcon} alt="Logout" />
          <p
            style={{
              left: retrunLanguage(i18n) ? "unset" : "10px",
              right: retrunLanguage(i18n) ? "13px" : "unset",
            }}
          >
            {t("profile.logout")}
          </p>
        </div>
      </div>
      <DetailsButton />
      <div className="profile-footer">
        <Footer showCommentOption={false} />
      </div>
      <Dialog
        open={openLogoutDialog}
        onClose={handleLogoutCancel}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
        sx={{
          overflow: "hidden",
          "& .MuiDialog-paper": {
            height: "204px",
            width: "327px",
            textAlign: "center",
            overflow: "hidden",
            overflowY: "hidden",

            "@media (min-width: 600px)": {
              left: retrunLanguage(i18n) ? "unset" : "0",
              right: retrunLanguage(i18n) ? "0" : "unset",
              position: "fixed",
              margin: "20px",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <img src={ExclaimIcon} alt="Exclaim" />
        </DialogTitle>{" "}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              style={{
                font: "normal normal bold 16px/18px Titillium Web",
                color: "#959FA8",
                whiteSpace: "pre-line",
              }}
              className="delete-para"
            >
              {t("wantTologout", { ns: "dialog" })}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            marginRight: retrunLanguage(i18n) ? "0px" : "35px",
            marginLeft: retrunLanguage(i18n) ? "35px" : "0px",
            marginBottom: "20px",
          }}
        >
          <Button
            onClick={handleLogoutCancel}
            sx={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              border: "1px solid #98694E",
              borderRadius: "12px",
              opacity: "1",
              width: "116px",
              height: "29px",

              "&:hover": {
                background: "#FFFFFF 0% 0% no-repeat padding-box",
              },
            }}
          >
            <span
              style={{
                font: "normal normal 600 16px/24px Titillium Web",
                color: "#98694E",
              }}
            >
              {t("back", { ns: "dialog" })}
            </span>
          </Button>

          <Button
            onClick={handleLogoutConfirm}
            sx={{
              background: "#98694E 0% 0% no-repeat padding-box",
              border: "1px solid #707070",
              borderRadius: "12px",
              opacity: "1",
              width: "116px",
              height: "29px",
              marginLeft: retrunLanguage(i18n) ? "0px !important" : "8px",
              marginRight: retrunLanguage(i18n) ? "8px !important" : "0px",

              "&:hover": {
                background: "#98694E 0% 0% no-repeat padding-box",
              },
            }}
          >
            <span
              style={{
                font: "normal normal 600 16px/24px Titillium Web",
                color: "#ffffff",
              }}
            >
              {t("confirm", { ns: "common" })}
            </span>{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProfileStructure;
