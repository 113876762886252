import React from "react";

import "../../screens/order/style.css";
import { LocationIcon } from "../../assets";

const Address = ({ address, i18n, serviceType, branchName }) => {
  return (
    <div className="orderaddress-text">
      <p className="orderformatted-address">
        {serviceType !== "pickup" && (
          <div
            id="location-img"
            className={i18n ? "left-margin" : "right-margin"}
          >
            <img src={LocationIcon} alt="" />
          </div>
        )}

        <div className="address-details">
          {serviceType === "pickup"
            ? `Pickup order from \n ${branchName}`
            : address?.address_type === "house"
            ? `House: ${address?.house_no}, Block: ${address?.block}, Street: ${address?.street}`
            : address?.address_type === "office"
            ? `Company: ${address?.company_name}, Block: ${address?.block}, Floor: ${address?.floor}`
            : `Apartment: ${address?.apartment_no}, Block: ${address?.block}, Street: ${address?.street}`}
        </div>
      </p>
    </div>
  );
};

export default Address;
