import { useEffect, useState } from "react";
import branchQueueStatus from "./getBranchQueueStatus"; // Ensure this path is correct
import branchQueueStatusGuest from "./getBranchQueueStatusGuest";

const usePollingQueueStatus = (
  branchId,
  restaurantId,
  isAlreadyQueue,
  isLoggedIn,
  interval = 30000
) => {
  const [queueData, setQueueData] = useState(null);
  // Memoized function to fetch queue status for logged-in users
  const fetchQueueStatus = async () => {
    try {
      const data = await branchQueueStatus(branchId, restaurantId);
      setQueueData(data);
    } catch (error) {
      console.error("Failed to fetch queue status:", error);
    }
  };

  // Memoized function to fetch queue status for guests
  const fetchQueueStatusGuest = async () => {
    try {
      const data = await branchQueueStatusGuest(branchId, restaurantId);
      setQueueData(data);
    } catch (error) {
      console.error("Failed to fetch queue status:", error);
    }
  };

  useEffect(() => {
    // Initial fetch based on login status
    if (isLoggedIn && isAlreadyQueue) {
      fetchQueueStatus();
    } else {
      fetchQueueStatusGuest();
    }

    // Set up polling based on login status
    const polling =
      isLoggedIn && isAlreadyQueue
        ? setInterval(fetchQueueStatus, interval)
        : setInterval(fetchQueueStatusGuest, interval);

    // Cleanup interval on component unmount or dependencies change
    return () => clearInterval(polling);

    // Add necessary dependencies to avoid infinite calls
  }, [interval]);

  return queueData;
};

export default usePollingQueueStatus;
