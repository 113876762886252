import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { Card } from "@mui/material";
import { setSelectedService } from "../../store/reducers/resturantsSlice";
import {
  heroSelectScript,
  selectServiceAvailable,
} from "../../utils/conversionScripts";
import "react-loading-skeleton/dist/skeleton.css";

import "./style.css";

const RestaurantCard = (props) => {
  const {
    // clickedButtonIndex,
    orderTypeFilter,
    restaurantsData,
    goToRestaurantMenuPage,
    selectedText,
    selectedFilter,
    filterSelectedNow,
    isLoading,
  } = props;

  const { t } = useTranslation(["landingPage"]);
  const supportRestaurant = useSelector(
    (state) => state.resturants.supportRestaurant
  );
  const dispatch = useDispatch();

  const newRestaurantData = [...(restaurantsData || [])];

  const skeletonCards = Array.from({ length: 7 }); // Number of skeleton cards to display

  const checkServiceStatus = (restaurant) => {
    const findRestaurant = supportRestaurant.find(
      (suppRestaurant) => suppRestaurant.id === restaurant.id
    );

    const serviceStatusObject = {
      dinein: findRestaurant?.dinein_status,
      catering: findRestaurant?.catering_status,
      delivery: findRestaurant?.delivery_status,
      pickup: findRestaurant?.pickup_status,
    };

    if (findRestaurant) {
      if (serviceStatusObject[selectedFilter]) {
        return true;
      }

      return false;
    }

    return false;
  };

  // const getClassNameAccordingToFilter = (orderTypeId, restaurant) => {
  //   if (
  //     orderTypeId === clickedButtonIndex &&
  //     orderTypeFilter &&
  //     orderTypeId !== 1
  //   ) {
  //     const { delivery, near, selectedNow } = orderTypeFilter;

  //     if (delivery && !near) {
  //       return "button-icons-styles-delivery";
  //     }

  //     if (!delivery && near && checkServiceStatus(restaurant)) {
  //       return "button-icons-styles-near";
  //     }

  //     if (delivery && near && checkServiceStatus(restaurant)) {
  //       return selectedNow === "delivery"
  //         ? "button-icons-styles-delivery"
  //         : "button-icons-styles-near";
  //     }
  //   }
  // };

  // const getServicesIcon = (serviceName, restaurant) => {
  //   return (
  //     <div className="icon-row">
  //       <img
  //         className={getClassNameAccordingToFilter(
  //           icons[serviceName].id,
  //           restaurant
  //         )}
  //         src={icons[serviceName]?.image}
  //         style={{ width: iconWidth, height: iconHeight }}
  //       />
  //     </div>
  //   );
  // };

  // Define the width and height for the SVG icons
  // const iconWidth = "9px";
  // const iconHeight = "11px";

  const serviceSelect = (serviceStatus, text) => {
    let noRoute;
    if (text === t("dinein") && serviceStatus?.dinein_status) {
      noRoute = "dinein";
    } else if (serviceStatus?.catering_status && text === t("catering")) {
      noRoute = "catering";
    } else if (serviceStatus?.delivery_status && text === t("delivery")) {
      noRoute = "delivery";
    } else if (serviceStatus?.pickup_status && text === t("pickup")) {
      noRoute = "pickup";
    } else {
      noRoute = null;
    }

    return noRoute;
  };

  const selectedFilterClass = (restaurant) => {
    const { delivery: availableForDelivery, near } = orderTypeFilter || {};

    const serviceStatusObject = {
      dinein: restaurant.dinein_status,
      catering: restaurant.catering_status,
      delivery: restaurant.delivery_status,
      pickup: restaurant.pickup_status,
    };

    // Return early if the selected filter status is not available
    if (selectedFilter === null) return;
    if (!serviceStatusObject[selectedFilter]) return "restaurantCard-disabled";

    const isFilterValid =
      filterSelectedNow !== t("nearMe") &&
      filterSelectedNow !== t("deliversToMe") &&
      filterSelectedNow !== "";

    if (availableForDelivery) {
      if (isFilterValid) {
        selectServiceAvailable(
          selectedText,
          selectedText === t("catering") ? null : filterSelectedNow,
          restaurant?.name_en
        );
      }

      return "restaurantCard-border-delivery ";
    }

    if (near && checkServiceStatus(restaurant) && filterSelectedNow !== "") {
      selectServiceAvailable(
        selectedText,
        filterSelectedNow,
        restaurant?.name_en
      );
      return "restaurantCard-border-near ";
    }

    return "restaurantCard-disabled";
  };

  return (
    <div
      className="restaurantCard-container container-fluid"
      id="parent-of-all"
    >
      {isLoading
        ? skeletonCards.map((_, index) => (
            <div key={index} className="restaurantCard-skeleton">
              <Skeleton  height={74} />
            </div>
          ))
        : newRestaurantData
            .sort(
              (restaurantA, restaurantB) =>
                restaurantA.display_order - restaurantB.display_order
            )
            .map((restaurant, index) => {
              return (
                <div
                  key={index}
                  data-type={restaurant.id}
                  className={`restaurantCards restaurantCard-large
                ${selectedFilterClass(restaurant)}`}
                  id="when-deliver"
                >
                  <Card
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      boxShadow: "none",
                      border: "none",
                      maxHeight: "98%",
                      position: "relative",
                    }}
                    className="restaurantCards"
                    onClick={() => {
                      // Disable the card if the 'restaurantCard-disabled' class is present
                      if (
                        selectedFilterClass(restaurant) ===
                        "restaurantCard-disabled"
                      ) {
                        return;
                      }
                      heroSelectScript("Resturants", restaurant?.name_en);

                    if (selectedText !== "") {
                      dispatch(
                        setSelectedService(
                          serviceSelect(restaurant, selectedText)
                        )
                      );
                    }
                    goToRestaurantMenuPage(restaurant.id);
                  }}
                >
                  <img
                    className="restaurantCard-img"
                    src={restaurant?.image}
                    alt={`${restaurant?.name_en}`}
                    width={151}
                    height={72}
                  />
                  {/* <div
                    className={`svg-container ${
                      clickedButtonIndex !== null
                        ? "expanded expanded-width"
                        : "collapsed"
                    }`}
                  >
                    <div
                      className="inner-svg-container"
                      id="inner-svg-container"
                      data-type={restaurant.id}
                    >
                      {restaurant.catering_status && (
                        <div className="icon-row">
                          <img
                            className={
                              selectedText === t("catering") &&
                              selectedText !== t("dinein") &&
                              selectedText !== t("delivery") &&
                              selectedText !== t("pickup")
                                ? "catering-bg"
                                : ""
                            }
                            src={icons["catering_status"]?.image}
                            // alt={`Icon ${i + 1}`}
                            style={{ width: "12px", height: "11px" }}
                          />
                        </div>
                      )}
                      {restaurant.delivery_status &&
                        getServicesIcon("delivery_status", restaurant)}
                      {restaurant.dinein_status &&
                        getServicesIcon("dinein_status", restaurant)}
                      {restaurant.pickup_status &&
                        getServicesIcon("pickup_status", restaurant)}
                    </div>
                  </div> */}
                </Card>
              </div>
            );
          })}
      </div>
  );
};

export default RestaurantCard;
