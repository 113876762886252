import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";

import { retrunLanguage } from "../../utils/languageSelect";
import {
  // daysOfWeek,
  getDayNameFromIndex,
  getSingleDigit24Hour,
  getSingleDigitMinutes,
  hasSameMonthAndDate,
} from "../../utils/dateTimeHandler";

import "./style.css";
import { CloseIcon } from "../../assets";

const currentDate = dayjs();

const DatePickerDialog = (props) => {
  const { t, i18n } = useTranslation("dialog");

  const {
    dineinDateTimeSelector,
    handleClose,
    isPickerType,
    setIsPickerType,
    setDineinDateTimeSelector,
    setDate,
    setTime,
    branches,
    dayObject,
  } = props;

  const branchId = +localStorage.getItem("branchId");

  const branchObj = branches?.find((branch) => {
    return branch.id === branchId;
  });
  const findPrevDay = getDayNameFromIndex(dayObject?.dayIndex);

  const selectedDay = branchObj?.branch_hours?.find(
    (weekDay) => weekDay.week_day === dayObject?.day
  );

  const previousDay = branchObj?.branch_hours?.find(
    (weekDay) => weekDay.week_day === findPrevDay
  );

  const [selectDate, setSelectDate] = useState(null);
  const [selectTime, setSelectTime] = useState(null);
  const [disablePast, setDisablePast] = useState(false);

  const singleDigitFromHour = getSingleDigit24Hour(selectedDay?.from_time);
  const singleDigitToHour = getSingleDigit24Hour(selectedDay?.to_time);
  const singleDigitToHourPrevDay = getSingleDigit24Hour(previousDay?.to_time);

  const singleDigitFromMinute = getSingleDigitMinutes(selectedDay?.from_time);
  const singleDigitToMinute = getSingleDigitMinutes(selectedDay?.to_time);
  const singleDigitToMinutePrevDay = getSingleDigitMinutes(
    previousDay?.to_time
  );

  const shouldDisableTime = (value) => {
    const hour = value.hour();
    const minute = value.minute();
    // const currentHour = currentDate.hour();
    // const currentMinute = currentDate.minute();

    let isWithinFirstRange = false;
    let isWithinFirstRangeM = false;
    let isWithinSecondRange = false;
    let isWithinSecondRangeM = false;

    if (singleDigitToHour < 12) {
      isWithinFirstRange = hour >= singleDigitFromHour && hour <= 24;
    } else {
      isWithinFirstRange =
        hour >= singleDigitFromHour && hour <= singleDigitToHour;

      if (hour === singleDigitFromHour) {
        isWithinFirstRangeM = minute < singleDigitFromMinute;
      }

      if (hour === singleDigitToHour) {
        isWithinFirstRangeM = minute > singleDigitToMinute;
      }
    }

    if (!hasSameMonthAndDate(currentDate, dayObject?.fullDate)) {
      if (singleDigitToHourPrevDay < 12) {
        isWithinSecondRange = hour >= 0 && hour <= singleDigitToHourPrevDay;

        if (hour === singleDigitToHourPrevDay) {
          isWithinSecondRangeM = minute > singleDigitToMinutePrevDay;
        }
      }
    }

    const isHourDisabled = !(isWithinFirstRange || isWithinSecondRange);
    const isMinuteDisabled = isWithinFirstRangeM || isWithinSecondRangeM;
    return isHourDisabled || isMinuteDisabled;
  };

  useEffect(() => {
    if (hasSameMonthAndDate(currentDate, dayObject?.fullDate)) {
      setDisablePast(true);
    } else {
      setDisablePast(false);
    }
  }, [dayObject]);

  useEffect(() => {
    if (hasSameMonthAndDate(currentDate, selectDate)) {
      setSelectTime("");
      setTime("");
    }
  }, [selectDate]);

  return (
    <Dialog
      open={dineinDateTimeSelector}
      onClose={() => handleClose()}
      sx={{
        margin: "0",
        textAlign: "center",
      }}
      PaperProps={{
        sx: {
          overflow: "visible",
          margin: "0",
          bottom: "0",
          position: "fixed",
          width: "375px",
          left: retrunLanguage(i18n) ? "unset" : "0",
          right: retrunLanguage(i18n) ? "0" : "unset",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",

          "@media (max-width: 500px)": {
            width: "100%",
          },
        },
      }}
    >
      <DialogTitle className="close-icon" onClick={() => handleClose()}>
        <img src={CloseIcon} />
      </DialogTitle>

      <DialogContent
        sx={{
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
        className="date-time-picker-dialog"
      >
        <Box className={`date-time-heading active-heading`}>
          <Button
            className={isPickerType === "date" ? `${isPickerType}-active` : ""}
            onClick={() => setIsPickerType("date")}
            disabled={isPickerType === "time"}
          >
            {t("date", { ns: "dinein" })}
          </Button>

          <Button
            className={isPickerType === "time" ? `${isPickerType}-active` : ""}
            onClick={() => setIsPickerType("time")}
            disabled={isPickerType === "date"}
          >
            {t("time", { ns: "dinein" })}
          </Button>
        </Box>

        {isPickerType === "date" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              disablePast={true}
              onChange={(date) => setSelectDate(date)}
            />
          </LocalizationProvider>
        )}

        {isPickerType === "time" && (
          <>
            <Box className="time-header">
              <Typography variant="h4">{t("hour")}</Typography>
              <Typography variant="h4">{t("minute")}</Typography>
              {/* <Box sx={{ width: "79px", marginRight: "12px" }} /> */}
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MultiSectionDigitalClock
                timeSteps={{ minutes: 10 }}
                minutesStep={10}
                onChange={(date) => setSelectTime(date)}
                ampm={false}
                disablePast={disablePast}
                shouldDisableTime={shouldDisableTime}
              />
            </LocalizationProvider>
          </>
        )}

        <Box sx={{ padding: "0px 28px", marginTop: "5px" }}>
          <Button
            className="date-time-confirm"
            onClick={() => {
              if (selectDate) {
                setDate(selectDate);
              }

              if (selectTime) {
                setTime(selectTime);
              }

              if (isPickerType === "date") {
                setIsPickerType("time");
              } else {
                setDineinDateTimeSelector(false);
              }
            }}
          >
            {t("confirm", { ns: "common" })}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DatePickerDialog;
